body {
  background-repeat:no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.enigma-container {
  height: 100%;
  width: 100%;
  margin-top: 100px;
/*   display: flex;
  justify-content: center;
  align-items: center; */
}

.enigma-image-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.enigma-image-container > img {
  border-radius: 10px;
  min-width: 350px;
  max-width: 400px;
  margin: 20px;
}


.MuiContainer-root {
  margin-top: 30px!important;
}

.enigma-content {

}
